<template>
	<div>
		<h1 class="sgpshop-h1">Assistenza</h1>
		<div class="mx-5">
			<div class="text-h6 ma-3 text-left">
				Per richieste di assistenza contattare il nostro ufficio ai seguenti
				recapiti:
			</div>
			<div class="ma-3">
				<span class="primary--text font-weight-bold">Mail:</span>
				produzione@sgpitalia.com
				<br />
				<span class="primary--text font-weight-bold"> Telefono:</span> +39 02
				20480364
				<br />
			</div>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
		};
	},
};
</script>

<template>
	<div>
		<v-breadcrumbs :items="breadcrumbsItems">
				<v-icon slot="divider" class="secondary--text text--lighten-2">fas fa-caret-right</v-icon>
			</v-breadcrumbs>
		<h1  class="sgpshop-h1 text-uppercase">{{ $t("buyBook") }}</h1>
		<v-container>
			<v-row>
				<v-col cols="12" md="6">
					<div class="text-center mb-5">
						<v-btn @click="showPdfViewer = 1" color="black" dark large>
						<v-icon left>fas fa-book-reader</v-icon>
						Sfoglia il libro</v-btn>
					</div>
					<v-img class="rounded" contain src="https://sgpphoto.blob.core.windows.net/sgpweb/Shop/Home/bookcover.jpg">
					</v-img>
				</v-col>
				<v-col cols="12" md="6" v-if="ItemDetails">
					<ShopItemCard :ItemDetails="ItemDetails"></ShopItemCard>
					<NFFBookCard></NFFBookCard>
				</v-col>
			</v-row>
		</v-container>
		<v-dialog fullscreen
			v-model="showPdfViewer"
			@keydown.esc="showPdfViewer = false">
			<v-toolbar color="black" dark>
				<v-toolbar-items>
					<v-btn @click="page--" :disabled="page===1">
						<v-icon small class="mr-3">fas fa-chevron-left</v-icon>
						Prev</v-btn>
					<v-btn @click="page++" :disabled="page === pageCount">
						Next
						<v-icon small class="ml-3">fas fa-chevron-right</v-icon></v-btn>
				</v-toolbar-items>
				<v-toolbar-title><span class="pl-3">Sfoglia il libro</span></v-toolbar-title>
				<v-spacer></v-spacer>
				<v-toolbar-items>
					<v-btn @click="showPdfViewer = false">{{ $t("common.close") }}</v-btn>
				</v-toolbar-items>
			</v-toolbar>
			<v-card>
				<div >
					<resize-sensor @resize="onContainerResize"></resize-sensor>
					<VuePdfEmbed source="https://sgpphoto.blob.core.windows.net/sgpweb/Shop/Project01/Book/Preview.pdf"
						:page="page"
						:height="pdfHeight"
						:width="pdfWidth"
					></VuePdfEmbed>
				</div>
			</v-card>
		</v-dialog>
	</div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import CrudClient from "@/services/CrudClient/";
import NFFBookCard from "../Shop/TimeToChange/NFFBookCard.vue";
import ShopItemCard from "../Shop/TimeToChange/ShopItemCard.vue";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed.js";
import ResizeSensor from "vue-resize-sensor";
export default {
	components: { NFFBookCard, ShopItemCard, VuePdfEmbed, ResizeSensor },
	data() {
		return {
			showPdfViewer: false,
			page: 1,
			pageCount: 18,
			pdfHeight: null,
			pdfWidth: null,
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				}],
			LoadingItemDetails: false,
			ItemDetails: null,
			ProjectDetails: null
		}
	},
	computed: {
		...mapGetters(["MainProject"]),
	},

	async mounted() {
		const sustainabilityProjecId = 1;
		await this.SetProject(sustainabilityProjecId);
		this.ProjectDetails = this.MainProject;

		this.breadcrumbsItems.push({
			text: "Time To Change",
			disabled: false,
			exact: true,
			to: "/TimeToChange",
		});

		this.breadcrumbsItems.push({
			text: this.$t("buyBook"),
			disabled: true,
			exact: true,
			to: "/TimeToChange/AcquistaLibro",
		});

		await this.LoadItemDetails(this.BookShopItemId);
	},
	created() {
		this.BookShopItemId = process.env.VUE_APP_BOOK_SHOP_ITEM_ID;
		this.ProjectChaptersService = new CrudClient("Shop/ProjectChapters");
		this.ShopItemsService = new CrudClient("Shop/Items");
	},

	methods: {
		...mapActions(["snackSuccess", "snackError", "SetProject"]),
		onContainerResize(size) {
			this.$log.debug(size);
			this.pdfWidth = size.width;
		},

		async LoadItemDetails(itemId) {
			try {
				this.LoadingItemDetails = true;
				this.ItemDetails = await this.ShopItemsService.GetSelectedFields(itemId,
					`*, ShopItemVersions.*,
ShopItemVersions.ShopItemVersionGroup.*, 
ShopItemVersions.ShopItemVersionGroup.ShopItemVersionOptions.* 
				`);

				this.ItemDetails.ShopItemVersions = this.ItemDetails.ShopItemVersions
					.filter(version => version.IsActive === true).sort((a, b) => (a.SortOrder > b.SortOrder) ? 1 : -1);
				this.ItemDetails.ShopItemVersions.forEach(element => {
					this.$set(element, "showDialog", false);
				});
			} catch (error) {
				this.snackError({ Text: this.$t("snackError_CannotLoadData") });
				this.$captureError(error);
			} finally {
				this.LoadingItemDetails = false;
			}
		},

	}

}
</script>
<i18n>
	{
		"it":{
"buyBook": "Acquista il Libro"
		},
		"en": {
"buyBook": "Buy the Book"
		}
	}
</i18n>
